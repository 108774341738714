import { HttpClient } from "@angular/common/http";
import { endpoints } from "../constants/endpoints";
import { environment } from "../../../../environments/environment";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  constructor(private http: HttpClient) {}

  findAll = (status?: string, from?: string, to?: string): Observable<any> => {
    if (status && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}?approveStatus=${status}&from=${from}&to=${to}`
      );
    else if (status != null && (from == null || to == null))
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}?approveStatus=${status}`
      );
    else if (status == null && from != null && to != null)
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}?from=${from}&to=${to}`
      );
    else
      return this.http.get(
        `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}`
      );
  };

  createPriceRequest = (data: any) =>
    this.http.post(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}`,
      data
    );

  createPricing = (data: any) =>
    this.http.post(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/foreign-pricing`,
      data
    );

  updatePricing = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/update-pricing`,
      data
    );

  republish = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/republish`,
      data
    );

  createTranslation = (data: any) =>
    this.http.post(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/translations`,
      data
    );

    updateTranslation = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/translations/${id}`,
      data
    );

  findProductPriceRequests = (id: string) =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/price-change-requests`
    );

  cancelPricing = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/pricings/${id}/cancel`,
      {}
    );

  approve = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/approve`,
      data
    );
  updateSellingPrice = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/selling-price`,
      data
    );

  reject = (id: string, data: any) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/reject`,
      data
    );

  reCheck = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/rereview`,
      {}
    );

  markAsFeatured = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/mark-as-featured`,
      {}
    );

  markAsExpired = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/expire`,
      {}
    );

  unmarkAsFeatured = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/unmark-as-featured`,
      {}
    );

  activatePricing = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/activate`,
      {}
    );

  desactivatePricing = (id: string) =>
    this.http.patch(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}/desactivate`,
      {}
    );

  findOne = (id: string) =>
    this.http.get(
      `${environment.base_url}/${environment.api_prefix}/${endpoints.ADMIN.BACKOFFICE.PRODUCT}/${id}`,
      {
        headers: {
          language: "AR",
        },
      }
    );
}
